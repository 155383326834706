@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

html {
  font-family: Open Sans, sans-serif;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  background-image: url('assets/wave.svg');
  background-color: #03151d;
}

.text-header {
  /* background: linear-gradient(to right, #F0F6F7 0%, #E0F1FF 100%); */
  /* background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #F0F6F7;
  margin-left: -4px;
}

.btn-install {
  text-decoration: none;
  position: absolute;
  border: none;
  font-size: 18px;
  font-weight: 700;
  font-family: inherit;
  color: #fff;
  width: 12em;
  height: 3em;
  line-height: 3em;
  text-align: center;
  background: linear-gradient(90deg,#FF9E1F,#f441a5,#ffeb3b,#FF9E1F);
  background-size: 300%;
  border-radius: 30px;
  z-index: 1;
}

.btn-install:hover {
  animation: ani 5s linear infinite;
  border: none;
}

@keyframes ani {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 400%;
  }
  }

.btn-install:before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(90deg,#FF9E1F,#f441a5,#ffeb3b,#FF9E1F);
  background-size: 400%;
  border-radius: 35px;
  transition: 1s;
  margin-left: 8px;
}

.btn-install:hover::before {
  filter: blur(10px);
}

.btn-install:active {
  background: linear-gradient(32deg,#FF9E1F,#f441a5,#ffeb3b,#FF9E1F);
}

.tooltip-text {
  position: absolute;
  right: -28px;
  visibility: hidden
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  z-index: 2
}

.tooltip .tooltip-text:after {
  border: 5px solid transparent;
  border-top-color: #555;
  content: "";
  left: 50%;
  margin-left: -5px;
  position: absolute;
  top: 100%
}

.text-aqua {
  color: #219bba
}

.border-gradient-br-blue-green-gray-900 {
  background: linear-gradient(90deg, #18181b, #18181b), linear-gradient(to bottom right, #60a5fa, #4ade80);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box
}

.hover\:border-gradient-tl-blue-green-gray-900:hover {
  background: linear-gradient(90deg, #18181b, #18181b), linear-gradient(to top left, #60a5fa, #4ade80);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box
}

.gradient-border-3 {
  border: 3px solid transparent
}

.font-quick {
  font-family: Quicksand, sans-serif
}

.font-work {
  font-family: Work Sans, sans-serif
}

.button-free {
  background-image: linear-gradient(115deg, #d8e2db, #44b5ce);
  border-radius: 11px;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  width: fit-content
}

.button-free span {
  background: #2f2f35;
  border-radius: 8px;
  display: block;
  margin: 3px;
  padding: 10px 2.5rem
}

.button-anon-pen {
  background-image: linear-gradient(115deg, #4fcf70, #0ecec4, #12bcfe, #44ce7b);
  border-radius: 11px;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  width: fit-content
}

.button-anon-pen:hover {
  animation: play 1.6s ease-in infinite
}

.button-anon-pen span {
  background: #18181b;
  border-radius: 8px;
  display: block;
  margin: 3px;
  padding: 10px 3rem
}

@keyframes play {
  0% {
    background-position: 0
  }

  20% {
    background-position: -110px
  }

  35% {
    background-position: -180px
  }

  50% {
    background-position: -210px
  }

  80% {
    background-position: -350px
  }

  to {
    background-position: -390px
  }
}

.bg-gradient {
  background: linear-gradient(90deg, #6699b6, #0082c8, #0082c8, #6699b6)
}

.bg-gradient-base {
  background-color: #0082c8
}

.enter {
  transition: all .7s ease-in
}

.leave {
  transition-delay: .2s;
  transition: all .2s ease-out
}

.to-top,
.visuallyhidden {
  opacity: 0
}

.to-top {
  align-items: center;
  background: #219bba;
  border-radius: 50%;
  bottom: 6px;
  color: #fff;
  display: flex;
  font-size: 32px;
  height: 40px;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  right: 32px;
  text-decoration: none;
  transition: all .4s;
  width: 40px;
  z-index: 999
}

.to-top.active {
  bottom: 25px;
  opacity: 1;
  pointer-events: auto
}

.modal {
  background-color: #000;
  background-color: rgba(0, 0, 0, .4);
  display: none;
  height: 100%;
  left: 0;
  overflow: auto;
  top: 0;
  z-index: 1000
}

.modal,
.modal-content {
  position: fixed;
  width: 100%
}

.modal-content {
  background-color: #fefefe;
  bottom: 0
}

.animation-fade-in {
  animation-duration: .4s;
  animation-name: fadeIn
}

.animation-fade-out {
  animation-duration: .4s;
  animation-name: fadeOut
}

.animation-slide-in {
  animation-duration: .4s;
  animation-name: slideIn
}

.animation-slide-out {
  animation-duration: .4s;
  animation-name: slideOut
}

.close {
  color: #fff;
  font-weight: 700
}

.close:focus,
.close:hover {
  color: #000;
  cursor: pointer;
  text-decoration: none
}

.modal-footer,
.modal-header {
  background-color: #219bba;
  color: #fff;
  padding: 12px 16px
}

@keyframes slideIn {
  0% {
    bottom: -300px;
    opacity: 0
  }

  to {
    bottom: 0;
    opacity: 1
  }
}

@keyframes slideOut {
  0% {
    bottom: 0;
    opacity: 1
  }

  to {
    bottom: -300px;
    opacity: 0
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

.logo-transfer {
  margin: 0 auto 1.5rem
}

.logo-momo {
  align-items: center;
  background-color: #a50064;
  border-radius: 5px;
  display: flex;
  height: 45px;
  justify-content: center;
  padding: 5px;
  width: 45px
}

/* Text typing */
p.text-typing {
  height: 3em;
  overflow: hidden;
  white-space: pre-line;
  color: #F0F6F7;
}

@media (min-width: 768px) {
  .text-typing {
    height: 2.5em;
    overflow: hidden;
    white-space: pre-line;
  }
}

#text-tying {
  border-right: .05em solid;
  animation: caret 1s steps(1) infinite;
}

@keyframes caret {
  50% {
    border-color: transparent;
  }
}

.donate-content-example {
  display: none;
}
